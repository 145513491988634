<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>{{ $route.name }}</h4></el-row
            ></el-col
          >
        </el-row>
      </template>

      <base-input
        v-model="incomingGrowTarget"
        type="money"
        label="Taxa de crescimento anual"
      >
        <template #append> % </template>
      </base-input>
      <base-input
        v-model="taxCorrection"
        label="Taxa de inflação anual"
        type="money"
      >
        <template #append> % </template>
      </base-input>
      <el-divider />
      <vue-apex-charts
        :key="chartKey"
        v-if="OrdersSeries?.length"
        :options="getAreaOptions()"
        :series="AllSeries"
        height="500"
      ></vue-apex-charts>
      <el-empty v-else></el-empty>
    </el-card>

    <filter-orders-modal
      :showModal="showFilterOrdersModal"
      :filters="filterOptions"
      @close-modal="showFilterOrdersModal = false"
      @update-filters="updateFilters"
    ></filter-orders-modal>
  </div>
</template>

<script>
import FilterOrdersModal from "./modals/FilterOrdersModal.vue";
import VueApexCharts from "vue3-apexcharts";
import OrderService from "../services/orders";
import { uuid } from "vue-uuid";
import {
  dateFormatter,
  currencyFormatter,
  numberFormatter,
} from "../utils/formatters";

export default {
  name: "CashierCloseResultsTrends",
  components: {
    FilterOrdersModal,
    VueApexCharts,
  },
  data: () => ({
    showFilterOrdersModal: false,
    orders: null,
    chartKey: uuid.v4(),
    filterOptions: {
      status: ["completed", "paid", "finalized"],
      delivered_at_start: getFirstDayInMonth(),
      delivered_at_end: new Date(),
    },
    isLoadingDownloadButton: false,
    isLoading: false,
    dateFormatter,
    currencyFormatter,
    numberFormatter,
    lastEighteenMonths: [],
    incomingGrowTarget: 20,
    taxCorrection: 5,
  }),
  mounted() {
    this.fetchOrders();
    this.loadLastHalfYearOrders();
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    AllSeries() {
      return [
        ...this.OrdersSeries,
        //...this.PublicOrdersSeries,
        //...this.PrivateOrdersSeries,
      ];
    },
    OrdersSeries() {
      if (!this.lastEighteenMonths.length) return [];
      const sumOrders = this.lastEighteenMonths;

      const lastSixMonthsMap = [];
      const lastYearMonthsMap = [];
      Array.from({ length: 6 }).forEach((_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i);
        lastSixMonthsMap.push(sumOrders[i].sum);
        date.setMonth(date.getMonth() - 12);
        lastYearMonthsMap.push(sumOrders[i + 12].sum);
      });
      lastSixMonthsMap.reverse();
      lastYearMonthsMap.reverse();

      const lastYearMonthsCorrection = lastYearMonthsMap.map((lastYear) => {
        return (
          lastYear *
          (1 +
            (Number(this.taxCorrection) + Number(this.incomingGrowTarget)) /
              100)
        );
      });

      const lastSixMonthsAvgAmount = lastSixMonthsMap.map((amount, index) => {
        return (
          amount /
            (sumOrders[lastSixMonthsMap.length - 1 - index]?.count || 1) || 0
        );
      });

      const lastYearProjection = lastYearMonthsMap.map(
        (lastYear, index, months) => {
          if (index !== months.length - 1) return 0;

          const days = new Date().getDate();
          const sundays =
            Math.floor(days / 7) + new Date().getDay() === 0 ? 1 : 0;
          return (lastYear / 30.4) * (days - sundays);
        }
      );

      return [
        {
          name: "Vendas atuais",
          data: lastSixMonthsMap,
          type: "column",
        },
        {
          name: "Vendas último ano",
          data: lastYearMonthsMap,
          type: "column",
        },
        {
          name: "Meta de vendas",
          data: lastYearMonthsCorrection,
          type: "column",
        },
        {
          name: "Projeção de vendas",
          data: lastYearProjection,
          type: "column",
        },
        {
          name: "Ticket medio",
          data: lastSixMonthsAvgAmount,
          type: "line",
          yAxisIndex: 1,
        },
      ];
    },
  },
  methods: {
    getMonthName(date) {
      return new Intl.DateTimeFormat("pt-BR", { month: "long" }).format(date);
    },
    async fetchSum(filters) {
      const { sum } = await OrderService().sum(filters);
      const { count } = await OrderService().count(filters);
      return { sum, count };
    },
    async loadLastHalfYearOrders() {
      const firstDayInMonth = getFirstDayInMonth();

      const lastEighteenMonths = Array.from({ length: 18 }).map((_, i) => {
        const _d = new Date(firstDayInMonth);
        _d.setMonth(_d.getMonth() - i);
        return _d;
      });

      const totals = lastEighteenMonths.map(async (date) => {
        const start_at = new Date(date);
        const end_at = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return await this.fetchSum({
          start_at,
          end_at,
          status: ["completed", "finalized", "paid"],
        });
      });

      const lastMonthsTotals = await Promise.all(totals);
      this.lastEighteenMonths = lastMonthsTotals.map(
        ({ sum: [{ total: totalSum }], count: [{ total: totalCount }] }) => ({
          sum: Number(totalSum) || 0,
          count: Number(totalCount) || 0,
        })
      );
    },
    rebuildChart() {
      this.chartKey = uuid.v4();
    },
    isPublicSell(order) {
      return order?.client?.legal?.social_name?.includes("PREGAO");
    },
    getAreaOptions() {
      return {
        chart: {
          type: "bar",
          zoom: {
            enabled: false,
          },
          locales: [
            {
              name: "pt-br",
              options: {
                months: [
                  "Janeiro",
                  "Fevereiro",
                  "Março",
                  "Abril",
                  "Maio",
                  "Junho",
                  "Julho",
                  "Agosto",
                  "Setembro",
                  "Outubro",
                  "Novembro",
                  "Dezembro",
                ],
                shortMonths: [
                  "Jan",
                  "Fev",
                  "Mar",
                  "Abr",
                  "Mai",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Set",
                  "Out",
                  "Nov",
                  "Dez",
                ],
                days: [
                  "Domingo",
                  "Segunda",
                  "Terça",
                  "Quarta",
                  "Quinta",
                  "Sexta",
                  "Sábado",
                ],
                shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                toolbar: {
                  exportToSVG: "Baixar SVG",
                  exportToPNG: "Baixar PNG",
                  exportToCSV: "Baixar CSV",
                  menu: "Menu",
                  selection: "Selecionar",
                  selectionZoom: "Selecionar Zoom",
                  zoomIn: "Aumentar",
                  zoomOut: "Diminuir",
                  pan: "Navegação",
                  reset: "Reiniciar Zoom",
                },
              },
            },
          ],
          defaultLocale: "pt-br",
        },
        //colors: ["#0d84ff", "#ff8c00", "#ff000f"],
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: "light",
          palette: "palette0",
        },
        stroke: {
          curve: "smooth",
          width: [0, 0, 0, 0, 4],
        },
        markers: {
          size: [0, 0, 0, 0, 4],
        },
        title: {
          text: "Vendas dos últimos 6 meses comparativo com último ano",
          align: "left",
        },
        xaxis: {
          categories: Array.from({ length: 6 })
            .map((_, i) =>
              this.getMonthName(
                new Date(new Date().setMonth(new Date().getMonth() - i))
              )
            )
            .reverse(),
        },
        yaxis: [
          {
            labels: {
              formatter: (v) => this.currencyFormatter.format(Number(v) || 0),
            },
            title: {
              text: "Vendas",
            },
          },
          {
            opposite: true,
            min: 0,
            max: 5000,
            labels: {
              formatter: (v) => this.numberFormatter.format(Number(v) || 0),
            },
            title: {
              text: "Ticket médio",
            },
          },
        ],
      };
    },
    sumTotalSold(index) {
      const _ = this.AllSeries[index];

      if (_) return _?.data?.reduce((t, e) => (t += e[1]), 0);
      else return 0;
    },
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    openFilterCashierModal() {
      this.showFilterOrdersModal = true;
    },
    updateFilters(filters) {
      this.filterOptions = filters || {};
      this.fetchOrders();
    },
    calculateResult(r) {
      return Number(Number(r.total_cashier) - Number(r.check_total)) || 0;
    },
    orderByFirms() {
      return {
        "COMERCIO DE CARNES DONAU EIRELI": JSON.parse(
          JSON.stringify(this.Orders)
        ),
      };
    },
    hasValidNumber(e) {
      return !isNaN(Number(e));
    },
    async fetchOrders() {
      this.isLoading = true;

      const { orders } = await OrderService().index(this.filterOptions);

      if (orders) this.orders = orders;

      this.isLoading = false;
      const self = this;
      setTimeout(() => self.rebuildChart(), 500);
    },
  },
};
const getFirstDayInMonth = () => {
  const _ = new Date();
  return new Date(_.getFullYear(), _.getMonth(), 1);
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #909399;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
}
.mt-1 {
  margin-top: 8px;
}
.summary-black {
  color: #333 !important;
}
</style>
